<script setup lang="ts">
import {
  DialogRoot,
  DialogTrigger,
  RadioGroupItem,
  RadioGroupRoot
} from "radix-vue";
import { computed, inject, ref, onMounted, onBeforeUnmount } from "vue";
import { useContentsStore } from "../../../stores/content";
import { useStore } from "../../../stores/store";
import { sidebarKey } from "../../common/sidebar/SidebarRoot.vue";
import AdminIcon from "@fluentui/svg-icons/icons/crown_20_filled.svg";
import MenuIcon from "@fluentui/svg-icons/icons/line_horizontal_3_20_regular.svg";
import EditIcon from "@fluentui/svg-icons/icons/settings_24_regular.svg";
import Icon from "@fluentui/svg-icons/icons/line_horizontal_1_dot_20_regular.svg";
import UpdateChannelDialog from "../UpdateChannelDialog.vue";
import DeleteChannelDialog from "../DeleteChannelDialog.vue";
import { useRoute } from "vue-router";
import { channel } from "diagnostics_channel";
import { useTokenStore } from "../../../stores/token";

const store = useStore();
const contentStore = useContentsStore();
const sidebarContext = inject(sidebarKey);
const route = useRoute();

// 선택된 채널 정보 가져오기
const selectedChat = computed(() => {
  return (
    store.allChannelList.find(
      (channel) => channel.channel_key === store.myChannelTabSelected
    ) || store.allChannelList[store.allChannelList.length - 1] // 기본값: 마지막 채널
  );
});

// 다이얼로그 표시 상태 관리
const showUpdateDialog = ref(false);
const showDeleteDialog = ref(false);
const selectedChannelForEdit = ref(null);
const selectedChannelForDelete = ref(null);

// 햄버거 메뉴 토글 상태 관리
const menuOpenStates = ref({});
// 현재 열린 드롭다운의 위치 정보
const dropdownPosition = ref({
  top: 0,
  left: 0,
  isOpen: false,
  channelKey: null
});

// 햄버거 메뉴 토글 함수
const toggleHamburgerMenu = (channelKey, event) => {
  event.stopPropagation();

  // 같은 버튼을 다시 눌렀을 경우 드롭다운 닫기
  if (menuOpenStates.value[channelKey]) {
    menuOpenStates.value[channelKey] = false;
    dropdownPosition.value.isOpen = false;
    return;
  }

  // 열려있는 메뉴 전부 닫기
  for (const key in menuOpenStates.value) {
    menuOpenStates.value[key] = false;
  }

  // 현재 메뉴 열기
  menuOpenStates.value[channelKey] = true;

  // 버튼 위치 계산하여 드롭다운 포지션 설정
  const button = event.currentTarget;
  const rect = button.getBoundingClientRect();

  dropdownPosition.value = {
    top: rect.bottom + window.scrollY + 5, // 버튼 하단에서 5px 아래
    left: rect.left + window.scrollX - 80, // 왼쪽에서 햄버거 메뉴 너비 고려하여 위치 조정
    isOpen: true,
    channelKey: channelKey
  };
};

// 메뉴 외부 클릭 시 닫기 함수
const closeAllMenus = () => {
  for (const key in menuOpenStates.value) {
    menuOpenStates.value[key] = false;
  }
  dropdownPosition.value.isOpen = false;
};

// 문서 클릭 이벤트 리스너 등록/해제
onMounted(() => {
  document.addEventListener("click", closeAllMenus);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", closeAllMenus);
});

// 구독 중인 채널 목록 필터링
const showMyChatRoomList = computed(() => {
  let filteredData = store.allChannelList.filter((item) =>
    ["S", "A", "E", "M"].some((type) =>
      (item.auth_type || "").split(",").includes(type)
    )
  );

  if (contentStore.searchText.trim() !== "") {
    filteredData = filteredData.filter((item) =>
      item.name
        .toLowerCase()
        .includes(contentStore.searchText.trim().toLowerCase())
    );
  }

  return filteredData;
});

// 채팅방 이동 함수
const goToChattingRoom = async (channel, index) => {
  console.log("channel입니다", channel);
  closeAllMenus();
  goToChatting(channel);
};

const goToChatting = (channel) => {
  console.log("채팅방 이동:", channel.name, channel.channel_key);
  sidebarContext?.toggleEnabled();
};

// 수정 다이얼로그 열기
const openEditDialog = (chat, event) => {
  if (!chat) return;
  event.stopPropagation();
  store.myChannelTabSelected = chat.channel_key; // 선택된 채널 설정
  closeAllMenus();
  sidebarContext?.toggleEnabled();
  // 채널 정보 설정 및 다이얼로그 표시
  selectedChannelForEdit.value = chat;
  showUpdateDialog.value = true;
};

// 삭제 다이얼로그 열기
const openDeleteDialog = (chat, event) => {
  if (!chat) return;
  event.stopPropagation();
  store.myChannelTabSelected = chat.channel_key; // 선택된 채널 설정
  closeAllMenus();
  sidebarContext?.toggleEnabled();
  // 채널 정보 설정 및 다이얼로그 표시
  selectedChannelForDelete.value = chat;
  showDeleteDialog.value = true;
};

const openDeletePop = () => {
  sidebarContext?.toggleEnabled();
};

const tokenStore = useTokenStore();

// 관리자 여부 확인
// const isAdmin = computed((channel) => {
//   channel = store.currentChannel?.channel_key;
//   const myInfo = store.myInfo;

//   if (!channel || !myInfo) {
//     return false; // 기본값으로 false를 반환하거나, 적절한 값을 반환
//   }
//   // const nsuYn = ref(false);
//   // if (store.myInfo?.user_key == 27 && currentChannel.channel_key == 250) {
//   //   return (nsuYn.value = true);
//   // }
//   return (
//     channel.auth_type === "A" ||
//     channel.auth_type === "E" ||
//     myInfo.observer_yn == "1"
//   );
// });


</script>

<template>
  <RadioGroupRoot v-model="store.myChannelTabSelected" orientation="vertical">
    <RadioGroupItem
      v-for="(chat, index) in showMyChatRoomList"
      :key="chat.channel_key"
      type="single"
      :value="`${chat.channel_key}`"
      @click="goToChattingRoom(chat, index)"
      class="channelItem"
    >
      <div class="chatItemWrap flexAlign">
        <div class="eachItemText">
          <div class="eachItemTitle flexAlign">
            <span class="fontMd">
              {{ chat.name }}
              <AdminIcon
                v-if="chat && chat.auth_type && chat.auth_type.includes('A')"
                class="admin-icon"
              />
              <p>{{ chat.channel_key }}</p>
            </span>
          </div>
        </div>
        <!-- <div
          v-if="
            isAdmin(chat.channel_key) &&
            tokenStore.isLogin &&
            store.myInfo?.user_key !== 102
          "
        >
          <EditIcon />
        </div> -->
        <!-- 관리자 또는 observer인 경우 햄버거 메뉴 표시 -->
        <div v-if="store.myInfo?.observer_yn == '1'" class="eachItemBtn">
          <div class="hamburger-menu-container">
            <button
              @click.stop="toggleHamburgerMenu(chat.channel_key, $event)"
              class="hamburger-button"
              aria-label="채널 메뉴"
            >
              <MenuIcon />
            </button>
          </div>
        </div>
      </div>
    </RadioGroupItem>

    <!-- 채널이 없을 때 표시할 메시지 -->
    <div v-if="showMyChatRoomList.length === 0" class="no-channels">
      구독 중인 채널이 없습니다.
    </div>
  </RadioGroupRoot>

  <!-- 전역 드롭다운 메뉴 (Teleport 활용) -->
  <!-- <Teleport to="body">
    <div
      v-if="dropdownPosition.isOpen"
      class="global-dropdown"
      :style="{
        top: `${dropdownPosition.top}px`,
        left: `${dropdownPosition.left}px`
      }"
      @click.stop
    >
      <DialogRoot v-if="dropdownPosition.channelKey">
        <DialogTrigger
          @click.stop="
            openEditDialog(
              showMyChatRoomList.find(
                (c) => c.channel_key === dropdownPosition.channelKey
              ),
              $event
            )
          "
          class="dropdown-item"
        >
          수정
        </DialogTrigger>
        <UpdateChannelDialog
          :channel="
            showMyChatRoomList.find(
              (c) => c.channel_key === dropdownPosition.channelKey
            )
          "
        />
      </DialogRoot>
      <DialogRoot v-if="dropdownPosition.channelKey">
        <DialogTrigger
          @click.stop="
            openDeleteDialog(
              showMyChatRoomList.find(
                (c) => c.channel_key === dropdownPosition.channelKey
              ),
              $event
            )
          "
          class="dropdown-item"
        >
          삭제
        </DialogTrigger>
        <DeleteChannelDialog
          :channel="
            showMyChatRoomList.find(
              (c) => c.channel_key === dropdownPosition.channelKey
            )
          "
        />
      </DialogRoot>
    </div>
  </Teleport> -->
  <!-- 전역 드롭다운 메뉴 (Teleport 활용) -->
  <Teleport to="body">
    <div
      v-if="dropdownPosition.isOpen"
      class="global-dropdown"
      :style="{
        top: `${dropdownPosition.top}px`,
        left: `${dropdownPosition.left}px`
      }"
      @click.stop
    >
      <button
        @click.stop="
          openEditDialog(
            showMyChatRoomList.find(
              (c) => c.channel_key === dropdownPosition.channelKey
            ),
            $event
          )
        "
        class="dropdown-item"
      >
        수정
      </button>
      <button
        @click.stop="
          openDeleteDialog(
            showMyChatRoomList.find(
              (c) => c.channel_key === dropdownPosition.channelKey
            ),
            $event
          )
        "
        class="dropdown-item"
      >
        삭제
      </button>
    </div>
  </Teleport>

  <!-- 다이얼로그 컴포넌트들을 별도로 Teleport로 렌더링 -->
  <Teleport to="body">
    <DialogRoot v-model:open="showUpdateDialog">
      <UpdateChannelDialog
        v-if="selectedChannelForEdit"
        :channel="selectedChannelForEdit"
      />
    </DialogRoot>

    <DialogRoot v-model:open="showDeleteDialog">
      <DeleteChannelDialog
        v-if="selectedChannelForDelete"
        :channel="selectedChannelForDelete"
      />
    </DialogRoot>
  </Teleport>
</template>

<style scoped lang="scss">
@use "../channelItem";

.chatItemWrap {
  gap: 10px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channelItem {
  padding: 10px 15px 10px 30px;
  transition: background-color 0.2s;
  position: relative;

  &:hover {
    background-color: #f5f5f5;
  }
}

.eachItemText {
  flex: 1;
  min-width: 0; /* overflow 방지를 위한 설정 */
}

.eachItemTitle {
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .fontMd {
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.eachItemTitle p {
  font-size: 10px;
  color: gray;
}

.admin-icon {
  flex-shrink: 0;
}

.lastChat,
.lastChatDate {
  font-size: 1rem;
  color: gray;
}

/* 햄버거 메뉴 스타일링 */
.hamburger-menu-container {
  position: relative;
  z-index: 10;
}

.hamburger-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
  box-shadow: none !important;

  &:hover {
    background-color: #f0f0f0;
  }
}

/* 전역 드롭다운 스타일 */
.global-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 100px;
  z-index: 1500; /* 높은 z-index로 설정 */
  animation: dropdown-appear 0.2s ease-out;
  border: 1px solid #eee;
}

.no-channels {
  padding: 20px;
  text-align: center;
  color: #888;
  font-size: 0.9rem;
}

@keyframes dropdown-appear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-item {
  display: block;
  padding: 8px 12px;
  text-align: left;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }
}

/* 반응형 스타일 */
@media (max-width: 480px) {
  .channelItem {
    padding: 10px 10px 10px 15px;
  }

  .global-dropdown {
    min-width: 80px;
  }

  .dropdown-item {
    padding: 6px 10px;
    font-size: 0.85rem;
  }
}
</style>
