<script setup lang="ts">
import {
  DialogRoot,
  DialogTrigger,
  SplitterGroup,
  SplitterPanel
} from "radix-vue";
import { computed, ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "../../../stores/store";

import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import HomeIcon from "@fluentui/svg-icons/icons/home_20_filled.svg";

/* 컴포넌트 */
import ManageKnow from "./ManageKnowCompo.vue";
import ManageMember from "./ManageMemberCompo.vue";
import ChannelDetailCompo from "./ChannelDetailCompo.vue";
import ChannelDetailRankCompo from "./ChannelDetailRanksCompo.vue";
import ManageCheck from "./ManageCheckListCompo.vue";
import ManageMornitor from "./ManageMornitorCompo.vue";
import ManageB2b from "./ManageB2b.vue";
import { useRouter } from "vue-router";
import PrivateChannelDialog from "../PrivateChannelDialog.vue";
import { useQuasar } from "quasar";
import { getChannelList, getKnowList, getMyInfo } from "../../../api/backend";
import CheckKnowAuthCompo from "./CheckKnowAuthCompo.vue";
import ManageUsersAuthCompo from "./ManageUsersAuthCompo.vue";
import KmsPageMain from "../webCollection/KmsPageMain.vue";
import { Channel } from "../../../api/types";

const store = useStore();
const router = useRouter();
const route = useRoute();
const $q = useQuasar();

// const contentStore = useContentsStore();
// const tabs = ["채널 홈", "검토목록", "지식관리", "B2B 연동", "멤버관리"];
// const tabs = ["채널 홈", "검토목록", "지식관리", "멤버관리"];
// const tabs = ["채널 홈", "검토목록", "지식관리", "권한카드", "멤버관리"];
// const tabs = ["채널 홈", "검토목록", "지식관리", "권한관리", "멤버관리"];
// const channelName = store.currentChannel?.name || "채널";
const channelName = computed(() => {
  return store.currentChannel?.name || "채널";
});

const channelKey = computed(() => {
  return store.currentChannel?.channel_key || 0;
});

const tabs = computed(() => {
  return ["채널 홈", "검토목록", "지식관리", "권한관리", "멤버관리"];
});

// const tabs = ["채널 홈", "검토목록", "지식관리", "지식권한", "멤버관리"];
// const tabs = ["채널 홈", "검토지식", "지식관리", "B2B 연동"];
// const tabs = ["검토목록", "지식관리"];

// 페이지 닫기 함수
const closePage = () => {
  // 브라우저 창/탭을 닫거나 이전 페이지로 이동
  // 1. 창/탭 닫기 (사용자가 직접 연 경우)
  if (window.opener) {
    window.close();
  }
  // 2. 이전 페이지로 이동 (다른 페이지에서 넘어온 경우)
  else if (window.history.length > 1) {
    router.go(-1);
  }
  // 3. 메인 페이지로 이동 (다른 방법이 없는 경우)
  else {
    router.push("/");
  }
};

const nowTab = ref(0);
const checkTab = ref(0);
const thirdTabs = ref(0);

const updateCheckTab = (value: number) => {
  console.log("updateCheckTab", value);
  checkTab.value = value;
};
//--------------- B2B연동 비밀번호 입력
const privateB2bDialog = ref(false);
const b2bOpenYn = ref(false);
const kindOfB2b = ref("B2b");

const openPrivateB2bDialog = () => {
  privateB2bDialog.value = true;
};

const closePrivateB2bDialog = () => {
  privateB2bDialog.value = false;
};

const selectedTab = async (index: number) => {
  nowTab.value = index;
  console.log("현재 탭", nowTab.value);
  // if (store.myInfo?.user_key == 27 && nowTab.value == 2) {
  //   router.push({
  //     name: "kmspage",
  //     params: { channelkey: store.currentChannel?.channel_key ?? null }
  //   });
  // }
  // if (nowTab.value == 2 && store.currentChannel?.kms_yn == 1) {
  //   router.push({
  //     name: "kmspage",
  //     params: { channelkey: store.currentChannel?.channel_key ?? null }
  //   });
  // }
  // if (nowTab.value == 3 && b2bOpenYn.value == false) {
  //   // privateDialog.value = false;
  //   openPrivateB2bDialog();
  //   nowTab.value = 0;
  // }
  if (nowTab.value == 1) {
    await store.updateAllChannelList();
    // await store.updateKnowCheck(store.currentChannel.channel_key);
  }

  if (nowTab.value == 0) {
    // const result = await getChannelList(store.currentChannel.channel_key);
    // $q.notify({
    //   message: "채널 갱신",
    //   color: "positive"
    // });
    // channel.value = result.values[0];
    // await store.updateKnowCheck(store.currentChannel.channel_key);
  }
  // if (nowTab.value == 2) {
  //   if (store.currentChannel?.kms_yn == 1) {
  //     router.push({
  //       name: "kmspage",
  //       params: { channelkey: store.currentChannel?.channel_key ?? null }
  //     });
  //   } else if (
  //     store.currentChannel?.kms_yn == 0 ||
  //     store.currentChannel?.kms_yn == null
  //   ) {
  //     console.log("==================updateKnowledge");
  //     await store.updateKnowledge(
  //       store.currentChannel.channel_key
  //       // store.currentChannel.lc_uuid
  //     );
  //   }
  // }
};

const changeTab = () => {
  console.log("탭전환", nowTab.value);
  checkTab.value = 1;
  thirdTabs.value = 1;
  nowTab.value = 1;
  console.log("탭전환2", nowTab.value);
};

// const goToB2bPage = () => {
//   openPrivateChatDialog();
//   if (password.value == mockupPw.value) {
//     $q.notify({
//       message: "웹 수집 페이지 이동",
//       color: "positive"
//     });
//     // collectPwYn.value = true;
//     password.value = "";
//     closePrivateChatDialog();
//     nowTab.value = 3;
//     b2bOpenYn.value = true;
//   } else if (password.value == "") {
//     $q.notify({
//       message: "비밀번호를 입력해주세요.",
//       color: "negative"
//     });
//   } else {
//     $q.notify({
//       message: "비밀번호가 틀렸습니다.",
//       color: "negative"
//     });
//   }
// };

//--------------- 웹 수집 비밀번호
const privateDialog = ref(false);
const pTitleValue = ref("비밀번호");
const password = ref("");
const mockupPw = ref("4217");
const collectionPopYn = ref(false);

const openPrivateChatDialog = () => {
  // console.log("상태확인", collectionPopYn.value);
  // if (collectionPopYn.value == true) {
  //   router.push(`/webpage`);
  // } else {
  //   privateDialog.value = true;
  // }
  privateDialog.value = true;
};

const closePrivateChatDialog = () => {
  privateDialog.value = false;
};

const goToWebPage = () => {
  openPrivateChatDialog();
  if (password.value == mockupPw.value) {
    $q.notify({
      message: "웹 수집 페이지 이동",
      color: "positive"
    });
    // collectPwYn.value = true;
    collectionPopYn.value = true; // 채널의 인증 상태 업데이트
    password.value = "";
    closePrivateChatDialog();
    router.push(`/talk/webpage`);
  } else if (password.value == "") {
    $q.notify({
      message: "비밀번호를 입력해주세요.",
      color: "negative"
    });
  } else {
    $q.notify({
      message: "비밀번호가 틀렸습니다.",
      color: "negative"
    });
  }
};

const kindOfWeb = ref("웹수집");

const channel = ref<Channel>();

// 페이지 로드 시 채널 정보 불러오기
onMounted(async () => {
  // URL에서 채널 키 가져오기 (라우트 파라미터에 따라 조정 필요)
  const channelKey =
    route.params.channelkey || localStorage.getItem("lastChannelKey");

  if (
    channelKey &&
    (!store.currentChannel || store.currentChannel.channel_key !== channelKey)
  ) {
    // 채널 정보 불러오기 (API 호출)
    // await store.fetchChannelInfo(channelKey);
    // 현재 채널 키를 로컬 스토리지에 저장
    localStorage.setItem("lastChannelKey", channelKey);
  }

  channel.value = store.currentChannel;
});

// 채널 정보 변경 시 로컬 스토리지에 저장
watch(
  () => store.currentChannel,
  (newChannel) => {
    if (newChannel && newChannel.channel_key) {
      localStorage.setItem("lastChannelKey", newChannel.channel_key);
    }
  },
  { deep: true }
);
</script>

<template>
  <SplitterGroup
    id="main"
    class="knowContentbox"
    as="main"
    direction="horizontal"
  >
    <!-- <SplitterPanel
      class="content searchbox2 flexAlign"
      style="height: calc(100vh - 85px); overflow-y: scroll"
    > -->
    <SplitterPanel
      class="content searchbox2 flexAlign"
      style="overflow-y: scroll"
    >
      <!-- <div class="channelDetailWrap backShadow flexJustify">
        <channelDetailCompo
          @go-to-check-ask-list="goToCheckAskList"
        ></channelDetailCompo>
        <channelDetailRankCompo></channelDetailRankCompo>
      </div> -->

      <!-- <div class="channelMangeWrap backShadow"> -->
      <div class="channelMangeWrap">
        <div class="tabWrap">
          <div class="channelNameTab" v-if="channelName">
            <HomeIcon class="homeIcon" /> {{ channelName }}
            <span class="channelKeyTab">{{ channelKey }}</span>
          </div>
          <div class="manageTabs flexAlign">
            <div
              class="fontBg eachTab cursorP"
              :class="{ selected: nowTab === index }"
              v-for="(tab, index) in tabs"
              :key="index"
              role="button"
              tabindex="1"
              @click="selectedTab(index)"
              @keydown.enter="selectedTab(index)"
            >
              {{ tab }}
            </div>
          </div>
          <!-- 닫기 버튼 (X) -->
          <div class="close-button" @click="closePage"><CloseIcon /></div>
          <!-- <button @click="goToWebPage" class="webPageBtn">+ 웹 수집</button> -->
          <!-- <DialogRoot>
            <DialogTrigger
              @click="openPrivateChatDialog"
              @click.stop
              class="webPageBtn"
              >+ 웹 수집
              <PrivateChannelDialog
                v-bind:open="privateDialog"
                :pClosePop="closePrivateChatDialog"
                :channel="store.currentChannel"
                :collectionPopYn="collectionPopYn"
                :goToWebPage="goToWebPage"
                v-model:password="password"
                :kindOf="kindOfWeb"
                :pTitle="pTitleValue"
              />
            </DialogTrigger>
          </DialogRoot> -->
          <!-- <DialogRoot>
            <PrivateChannelDialog
              v-bind:open="privateB2bDialog"
              :pClosePop="closePrivateB2bDialog"
              :channel="store.currentChannel"
              :collectionPopYn="collectionPopYn"
              :goToB2bPage="goToB2bPage"
              v-model:password="password"
              :kindOf="kindOfB2b"
              :pTitle="pTitleValue"
            />
          </DialogRoot> -->
        </div>

        <!-- <div class="manageBodyWrap">
          <manageMornitor v-if="nowTab == 0" />
          <manageCheck v-if="nowTab == 1" />
          <manageMember v-if="nowTab == 3" />
          <manageKnow v-if="nowTab == 2" />
        </div> -->
        <div class="manageBodyWrap">
          <div v-if="nowTab == 0">
            <!-- <ChannelDetailCompo :channel="store.currentChannel" /> -->
            <ChannelDetailCompo :channel="channel" />
            <!-- <ManageMornitor /> -->
          </div>
          <ManageCheck
            v-if="nowTab == 1"
            :checkNowTab="checkTab"
            :thirdTabs="thirdTabs"
            :updateCheckTab="updateCheckTab"
          />
          <!-- <ManageKnow
            v-if="nowTab == 2"
            :checkNowTab="checkTab"
            :changeTab="changeTab"
          /> -->
          <KmsPageMain
            v-if="nowTab == 2"
            :checkNowTab="checkTab"
            :changeTab="changeTab"
          />
          <CheckKnowAuthCompo
            v-if="nowTab == 3"
            :checkNowTab="checkTab"
            :changeTab="changeTab"
          />
          <ManageUsersAuthCompo
            v-if="nowTab == 4"
            :checkNowTab="checkTab"
            :changeTab="changeTab"
          />
          <!-- <ManageB2b v-if="nowTab == 4" /> -->
          <!-- <ManageB2b v-if="nowTab == 3" /> -->
          <!-- <manageMornitor v-if="nowTab == 0" /> -->
          <!-- <ManageMember v-if="nowTab == 5" /> -->
        </div>
      </div>
    </SplitterPanel>
  </SplitterGroup>
</template>
<style lang="scss" scoped>
@use "../../../styles/responsive";
@use "../../../styles/commons";

$splitBorder: 1px solid commons.$mainBorderColor;
.knowContentbox {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* border-top: $splitBorder; */
  /* background-color: commons.$mainBackground; */
  background-color: #fff;
  overflow-y: scroll;
}
.content {
  flex: 1;
  display: flex;
  /* min-height: 100%; */
  /* padding-block: 10px; */
  /* padding-inline: 20px; */
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE와 Edge에서 스크롤 바 숨기기 */
  scrollbar-width: none; /* Firefox에서 스크롤 바 숨기기 */
  /* margin: 0 auto; */

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive.tablet {
    /* padding-inline: 20rem; */
    /* max-width: 1024px; */
  }

  & .channelDetailWrap {
    width: 100%;
    height: auto;
    /* min-height: 500px; */
    background-color: #fff;
    /* border: 1px solid gray; */
    padding: 40px;
    margin-top: 10px;
    flex-direction: column;
    gap: 30px;
  }
}

.splitter {
  position: relative;
  border-left: $splitBorder;
}
.toggleButton {
  position: absolute;
  transform: translateX(-100%);
  left: 0px;
  top: calc(50vh - var(--header-logo-height));
  padding-inline: 5px;
  padding-block-start: 8px;
  padding-block-end: 10px;
  writing-mode: vertical-rl;
  font-weight: 700;

  $radius: 10px;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  border: 1px solid #8f8f9d;
}
.toggleIcon {
  transform: translateX(-0.5px);
}

.searchbox {
  position: relative;
  flex-direction: column;
  text-align: left;
  background-color: commons.$mainBackground;
}
.searchbox2 {
  position: relative;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  background-color: #fff;
}

.tabWrap {
  display: flex;
  gap: 15px;
}
.webPageBtn {
  font-size: 19px;
  display: flex;
  color: white;
  font-weight: 700;
  background-color: #595959;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.channelMangeWrap {
  width: 100%;
  /* border: 1px solid gray; */
  /* padding: 30px 10px 10px 10px; */
  padding: 30px 50px;
  background-color: #fff;
  /* height: auto; */
  height: 100vh;
  /* margin-top: 40px; */
  /* height: 100%; */

  & .manageTabs {
    width: 100%;
    /* max-width: 800px; */
    max-width: 660px;
    justify-content: space-between;
    position: sticky;
    // background-color: #fff;
    background-color: #f8f8f8 !important;
    margin: 0 auto;
    // left: 0;
    top: -1px;
    border: 0.3px solid #c7c7c7;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    /* z-index: 2; */

    & .eachTab {
      font-size: 15px;
      width: 100%;
      padding: 8px 0 4px;
      text-align: center;
      /* border-bottom: 1px solid gray; */
      /* background-color: #fff; */
      // color: #222;
      color: #555;
      transition: all 0.2s ease;
      /* border-radius: 10px 10px 0 0; */

      &.selected {
        font-weight: bold;
        /* border-bottom: 2px solid #fff; */
        color: #fff;
        border-radius: 8px;
        background-color: rgb(84, 84, 84);
      }
    }
  }

  & .manageBodyWrap {
    /* width: 100%;
    height: 100%;
    overflow-y: hidden;
    min-height: calc(100vh - 70px);
    padding: 0px 0px 20px; */
    width: 100%;
    overflow-y: auto;
    height: 90%;
  }
}

.tabWrap {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  margin-bottom: 20px;
}

.channelNameTab {
  border: none !important;
  font-size: 20px !important;
  color: #444;
  font-weight: 600 !important;
  position: relative;
  background-color: transparent;
  /* padding: 0 15px 0 5px; */
  /* margin-right: 15px; */
  display: flex;
  align-items: center;
  /* max-width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.homeIcon {
  margin-right: 5px;
}

.channelKeyTab {
  font-size: 0.65rem;
  font-weight: 400;
  color: gray !important;
  padding-left: 10px;
}

/* 채널명과 탭 사이 구분자 */
.channelNameTab::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  /* width: 1px; */
  width: 0px;
  background-color: #ddd;
}

/* @media (max-width: 768px) { */
@media (max-width: 770px) {
  .tabWrap {
    flex-wrap: wrap;
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .channelNameTab {
    width: 100%;
    margin-bottom: 10px;
    font-size: 18px !important;
    text-align: center;
    padding: 0;
  }

  .channelNameTab::after {
    display: none;
  }

  .manageTabs {
    max-width: 100% !important;
  }
}

@media (max-width: 480px) {
  .channelMangeWrap {
    padding: 20px;
  }

  // .channelMangeWrap {
  //   & .manageTabs {
  //     & .eachTab {
  //       font-size: 12px;
  //     }
  //   }
  // }
  .manageTabs .eachTab {
    font-size: 13px;
    padding: 8px 0;
  }

  .channelNameTab {
    font-size: 16px !important;
  }
}

.close-button {
  cursor: pointer;
  color: #777;
  margin-left: auto;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}
</style>
