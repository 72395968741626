<!-- AddDetailKnowAuthPop.vue -->
<script setup lang="ts">
import {
  onMounted,
  ref,
  computed,
  watch,
  nextTick,
  defineProps,
  PropType
} from "vue";
import { useStore } from "../../../../stores/store";
import {
  getKmsFolderGroupList,
  getKmsFolderList
} from "../../../../api/backend";
import KMSFolderIcon from "@fluentui/svg-icons/icons/folder_20_regular.svg";
import FolderTreeItem from "../../../root/webCollection/FolderTreeItem.vue";
import FolderWithAddButton from "../../webCollection/FolderWithAddButton.vue";
import { useQuasar } from "quasar";

interface SavedFolder {
  folder_name: string;
  kms_folder_key: number;
}

interface Props {
  open: boolean;
  authorizedItems: SavedFolder[];
  onSave: (items: SavedFolder[]) => void;
  onClose: () => void;
}

interface FolderGroup {
  kms_folder_group_key: number;
  channel_key: number;
  cre_date: string | null;
  folder_group_system_code: string | null;
  cre_user_key: number;
  folder_group_name: string;
  folder_group_type: string;
  delete_yn: number;
}

interface SubFolder {
  // 독 업데이트
  // doc_key: number;
  channel_key: number;
  folder_name: string;
  confirm_yn: number;
  kms_folder_key: number;
  parent_folder_key: number;
  children?: SubFolder[];
}

const props = defineProps<Props>();
const emits = defineEmits(["update:open", "save"]);

const store = useStore();
const $q = useQuasar();
const selectedFolder = ref<string>("");
const tempAuthorizedItems = ref<SavedFolder[]>([]);
const unauthorizedItems = ref<FolderGroup[]>([]);
const folderGroups = ref<FolderGroup[]>([]);
const subFolders = ref<SubFolder[]>([]);
const selectedTopFolder = ref<FolderGroup | null>(null);
const selectedFolderId = ref<number | null>(null);
const expandedFolders = ref(new Set<number>());

const fetchFolderGroups = async () => {
  const channelKey = store.currentChannel?.channel_key ?? 0;
  try {
    const response = await getKmsFolderGroupList(channelKey);
    folderGroups.value = Array.isArray(response)
      ? response.filter((group) => group.delete_yn === 0)
      : [response].filter((group) => group.delete_yn === 0);
    console.log("🚀 가져온 폴더 그룹:", response);
  } catch (error) {
    console.error("📢 폴더 그룹 가져오기 실패:", error);
  }
};

const topLevelFolders = computed(() =>
  folderGroups.value.map((group) => group.folder_group_name)
);

const fetchSubFolders = async (folderGroupKey: number) => {
  try {
    const response = await getKmsFolderList(folderGroupKey);

    // 응답 데이터 필터링 및 정렬
    const filteredFolders = Array.isArray(response)
      ? response.filter((folder) => {
          const selectedGroup = folderGroups.value.find(
            (group) => group.kms_folder_group_key === folderGroupKey
          );
          return (
            selectedGroup &&
            folder.channel_key === selectedGroup.channel_key &&
            folder.delete_yn !== 1
          );
        })
      : [];

    // 정렬
    const sortedFolders = filteredFolders.sort(
      (a, b) => a.kms_folder_key - b.kms_folder_key
    );

    // 폴더 계층 구조 구성
    subFolders.value = buildFolderHierarchy(sortedFolders);

    selectedTopFolder.value =
      folderGroups.value.find(
        (group) => group.kms_folder_group_key === folderGroupKey
      ) || null;

    console.log("하위 폴더 구조:", subFolders.value);
    return subFolders.value;
  } catch (error) {
    console.error("하위 폴더 불러오기 실패:", error);
    subFolders.value = [];
    selectedTopFolder.value = null;
  }
};

const buildFolderHierarchy = (folders: SubFolder[]): SubFolder[] => {
  const folderMap = new Map<number, SubFolder>();
  const rootFolders: SubFolder[] = [];

  // 먼저 모든 폴더를 맵에 저장
  folders.forEach((folder) => {
    folderMap.set(folder.kms_folder_key, { ...folder, children: [] });
  });

  // 계층 구조 구성
  folders.forEach((folder) => {
    const currentFolder = folderMap.get(folder.kms_folder_key);
    if (currentFolder) {
      if (folder.parent_folder_key === 0) {
        rootFolders.push(currentFolder);
      } else {
        const parentFolder = folderMap.get(folder.parent_folder_key);
        if (parentFolder) {
          if (!parentFolder.children) {
            parentFolder.children = [];
          }
          parentFolder.children.push(currentFolder);
        }
      }
    }
  });

  return rootFolders;
};

const toggleFolder = (folderId: number) => {
  if (expandedFolders.value.has(folderId)) {
    expandedFolders.value.delete(folderId);
  } else {
    expandedFolders.value.add(folderId);
  }
};

const isExpanded = (folderId: number) => {
  return expandedFolders.value.has(folderId);
};

const hasChildren = (folderId: number) => {
  return subFolders.value.some(
    (folder) => folder.parent_folder_key === folderId
  );
};

const handleFolderClick = async (folderId: number) => {
  // 폴더 선택 상태 업데이트
  if (selectedFolderId.value === folderId) {
    selectedFolderId.value = null;
  } else {
    selectedFolderId.value = folderId;
    // 하위 폴더 데이터 가져오기
    const result = await fetchSubFolders(folderId);
    console.log("result", result);
  }

  // 폴더 확장/축소 상태 토글
  toggleFolder(folderId);
};

const handleFolderChange = async (folderName: string) => {
  selectedFolder.value = folderName;
  const selectedGroup = folderGroups.value.find(
    (group) => group.folder_group_name === folderName
  );

  if (selectedGroup) {
    // 폴더 그룹이 선택되면 바로 하위 폴더 구조를 가져옴
    await fetchSubFolders(selectedGroup.kms_folder_group_key);
    // 선택된 폴더 그룹을 자동으로 확장
    expandedFolders.value.add(selectedGroup.kms_folder_group_key);
  } else {
    // 선택이 해제되면 하위 폴더 구조를 비움
    subFolders.value = [];
  }

  updateUnauthorizedItems();
};

const updateUnauthorizedItems = () => {
  unauthorizedItems.value = [...folderGroups.value].filter(
    (folder) =>
      !tempAuthorizedItems.value.some(
        (authorized) =>
          authorized.kms_folder_key === folder.kms_folder_group_key
      )
  );
};

const addToAuthorized = (item: FolderGroup | SubFolder) => {
  const newItem: SavedFolder = {
    folder_name:
      "folder_name" in item ? item.folder_name : item.folder_group_name,
    kms_folder_key:
      "kms_folder_key" in item ? item.kms_folder_key : item.kms_folder_group_key
  };

  const isDuplicate = tempAuthorizedItems.value.some(
    (existing) => existing.kms_folder_key === newItem.kms_folder_key
  );

  if (!isDuplicate) {
    tempAuthorizedItems.value = [...tempAuthorizedItems.value, newItem];
    unauthorizedItems.value = unauthorizedItems.value.filter(
      (item) => item.kms_folder_group_key !== newItem.kms_folder_key
    );

    nextTick(() => {
      updateUnauthorizedItems();
    });
  }
};

const removeFromAuthorized = (item: SavedFolder) => {
  tempAuthorizedItems.value = tempAuthorizedItems.value.filter(
    (i) => i.kms_folder_key !== item.kms_folder_key
  );
  updateUnauthorizedItems();
};

const handleSave = () => {
  emits("save", tempAuthorizedItems.value);
  handleClose();
  // $q.notify({
  //   message: "권한 카드가 수정되었습니다.",
  //   color: "positive",
  // });
};

const handleClose = () => {
  emits("update:open", false);
  if (props.onClose) {
    props.onClose();
  }
};

const filteredUnauthorizedItems = computed(() => {
  if (!selectedFolder.value) return [];

  return unauthorizedItems.value.filter((folder) => {
    const isSelected = folder.folder_group_name === selectedFolder.value;
    const isNotAuthorized = !tempAuthorizedItems.value.some(
      (auth) => auth.kms_folder_key === folder.kms_folder_group_key
    );
    return isSelected && isNotAuthorized;
  });
});

// const initializeData = async () => {
//   await fetchFolderGroups();
//   tempAuthorizedItems.value = [...props.authorizedItems];
//   selectedFolder.value = "";
//   updateUnauthorizedItems();
// };
const initializeData = async () => {
  await fetchFolderGroups();
  tempAuthorizedItems.value = [...props.authorizedItems];

  // 폴더 그룹이 로드된 후 첫 번째 폴더를 선택
  if (folderGroups.value.length > 0) {
    selectedFolder.value = folderGroups.value[0].folder_group_name;
    await handleFolderChange(selectedFolder.value); // 첫 번째 폴더의 하위 폴더도 불러오기
  }

  updateUnauthorizedItems();
};

watch(
  () => props.open,
  (newValue) => {
    if (newValue) {
      initializeData();
    }
  }
);

watch(
  () => props.authorizedItems,
  (newValue) => {
    if (props.open) {
      tempAuthorizedItems.value = [...newValue];
      updateUnauthorizedItems();
    }
  },
  { deep: true }
);

onMounted(fetchFolderGroups);
</script>

<template>
  <div v-if="open" class="popup-overlay">
    <div class="popup-content">
      <div class="popup-header">
        <div class="header-content">
          <h2>권한 지식 관리</h2>
        </div>
      </div>

      <div class="popup-body">
        <!-- <div class="input-group">
          <label>지식 폴더</label>
          <select
            v-model="selectedFolder"
            @change="handleFolderChange($event.target.value)"
            class="folder-select"
          >
            <option value="">폴더 선택</option>
            <option
              v-for="group in folderGroups"
              :key="group.kms_folder_group_key"
              :value="group.folder_group_name"
            >
              {{ group.folder_group_name }}
            </option>
          </select>
        </div> -->

        <div class="knowledge-container">
          <div class="knowledge-section">
            <h3>추가 가능한 폴더</h3>
            <div class="tree-list">
              <!-- <div
                v-for="folder in filteredUnauthorizedItems"
                :key="folder.kms_folder_group_key"
                class="folder-tag-container"
              > -->
              <div class="folder-select-container">
                <select
                  v-model="selectedFolder"
                  @change="handleFolderChange($event.target.value)"
                  class="folder-select"
                >
                  <option value="">--폴더 선택</option>
                  <option
                    v-for="group in folderGroups"
                    :key="group.kms_folder_group_key"
                    :value="group.folder_group_name"
                  >
                    {{ group.folder_group_name }}
                  </option>
                </select>

                <!-- 선택된 폴더의 하위 구조 표시 -->
                <!-- <div
                  v-if="isExpanded(folder.kms_folder_group_key)"
                  class="sub-folders"
                > -->
                <div
                  v-if="selectedFolder && subFolders.length > 0"
                  class="sub-folders-container"
                >
                  <!-- <div
                    v-for="subFolder in subFolders"
                    :key="subFolder.kms_folder_key"
                    class="folder-item-container"
                  >
                    <FolderTreeItem
                      :folder="subFolder"
                      :level="1"
                      :selected-folder-key="selectedFolderId"
                      :expanded-folders="expandedFolders"
                      @add-folder="addToAuthorized"
                    />
                  </div> -->

                  <FolderWithAddButton
                    v-for="subFolder in subFolders"
                    :key="subFolder.kms_folder_key"
                    :folder="subFolder"
                    :level="1"
                    :selected-folder-key="selectedFolderId"
                    :expanded-folders="expandedFolders"
                    @add-folder="addToAuthorized"
                  />
                  <!-- <FolderTreeItem
                    v-for="subFolder in subFolders"
                    :key="subFolder.kms_folder_key"
                    :folder="subFolder"
                    :level="1"
                    :selected-folder-key="selectedFolderId"
                    :expanded-folders="expandedFolders"
                    @add-folder="addToAuthorized"
                  >
                    <span class="add-icon" @click="addToAuthorized(folder)"
                      >+</span
                    >
                  </FolderTreeItem> -->
                </div>
                <!-- <div
                  class="folder-tag"
                  @click="handleFolderClick(folder.kms_folder_group_key)"
                >
                  <div class="folder-header">
                    <span
                      class="toggle-icon"
                      v-if="hasChildren(folder.kms_folder_group_key)"
                      @click.stop="
                        handleFolderClick(folder.kms_folder_group_key)
                      "
                    >
                      {{ isExpanded(folder.kms_folder_group_key) ? "▼" : "▶" }}
                    </span>
                    <span class="folder-name">{{
                      folder.folder_group_name
                    }}</span>
                  </div>

                  <div
                    v-if="isExpanded(folder.kms_folder_group_key)"
                    class="sub-folders"
                  >
                    <FolderTreeItem
                      v-if="subFolders.length"
                      v-for="subFolder in subFolders"
                      :key="subFolder.kms_folder_key"
                      :folder="subFolder"
                      :level="1"
                      :selected-folder-key="selectedFolderId"
                      :expanded-folders="expandedFolders"
                      @add-folder="addToAuthorized"
                    />
                  </div>
                </div> -->
                <!-- <span class="add-icon" @click="addToAuthorized(folder)">+</span> -->
              </div>
            </div>
          </div>
          <div class="knowledge-section">
            <h3>설정된 폴더</h3>
            <div class="auth-list">
              <div
                v-for="item in tempAuthorizedItems"
                :key="item.kms_folder_key"
                class="folder-tag-container"
              >
                <!-- <input type="checkbox" /> -->
                <div class="folder-tag">{{ item.folder_name }}</div>
                <span class="remove-icon" @click="removeFromAuthorized(item)"
                  >×</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button class="save-button" @click="handleSave">저장</button>
          <button class="cancel-button" @click="handleClose">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 8px;
  min-width: 600px;
  width: 65vw;
  height: 600px;
  max-width: 1020px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.popup-header {
  padding: 16px;
  border-bottom: 1px solid #eee;

  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }

    .close-button {
      position: absolute;
      right: 0;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      padding: 0 8px;
      color: #666;
      box-shadow: none;

      &:hover {
        color: #333;
      }
    }
  }
}

.popup-body {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 5px;

  label {
    width: 70px;
    text-align: left;
  }

  select.folder-select {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    min-width: 0;
    height: 32px;

    &:focus {
      outline: none;
      border-color: #454545;
    }
  }
}

.knowledge-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: hidden;

  .knowledge-section:first-child {
    flex: 5;
  }

  .knowledge-section:last-child {
    flex: 5;
  }
}

.knowledge-section {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 160px;

  h3 {
    margin: 0 0 12px 0;
    font-size: 14px;
    color: #666;
    line-height: 15px !important;
  }
}

.tree-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px;

  .folder-tag-container {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .folder-tag {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 6px;
    font-size: 13px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.folder-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-icon {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  width: 20px;
  text-align: center;
}

.nested-folders {
  border-left: 1px dashed #ddd;
  margin-top: 8px;
  margin-left: 20px;
}

.sub-folders {
  border-left: 1px dashed #ddd;
  margin-left: 20px;

  .sub-folder-row {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;

    .tree-indent {
      color: #999;
      font-size: 12px;
      min-width: 20px;
    }

    .folder-tag {
      flex: 1;
      padding: 4px 8px;
      border-radius: 16px;
      margin: 2px 0;
    }
  }
}

.auth-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .folder-tag-container {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .folder-tag {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    /* border-radius: 16px; */
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 13px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #e9e9e9;
    }
  }
}

.add-icon,
.remove-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;

  button {
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    box-shadow: none !important;

    &.save-button {
      background-color: #51c2b8;
      color: white;
      font-weight: 600;
    }

    &.save-button:hover {
      background-color: #18a498;
    }

    &.cancel-button {
      background-color: #f5f5f5;
      font-weight: 600;
    }

    &.cancel-button:hover {
      background-color: #ddd;
    }
  }
}

.folder-select-container {
  margin-bottom: 16px;

  .folder-select {
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #454545;
    }
  }
}

// .sub-folders-container {
//   margin-top: 12px;
//   padding: 8px;
//   border: 1px solid #eee;
//   border-radius: 4px;
//   background-color: #fff;
// }

.folder-item-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .add-icon {
    margin-top: 4px; // FolderTreeItem과 수직 정렬을 맞추기 위한 여백
  }
}

// .sub-folders-container {
//   margin-top: 12px;
//   padding: 12px;
//   border: 1px solid #eee;
//   border-radius: 4px;
//   background-color: #fff;
// }
</style>
